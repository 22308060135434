@media print {
        
    /*print-standard*/
    table {
        -webkit-print-color-adjust: exact;
        color-adjust: exact;
    }

    /*print-bare*/
    /*no background on cells*/
    .print-bare *,
    .print-bare :after,
    .print-bare :before{
        color:#000!important;text-shadow:none!important;-webkit-box-shadow:none!important;box-shadow:none!important;
    }
    .print-bare *{
        background:0 0!important;
    }

    .print-bare table {
        -webkit-print-color-adjust: unset;
        color-adjust: unset;
    }

    .print-bare .table td,.print-bare .table th{
        background-color:#fff!important;
    }
    .print-bare .table-bordered td,
    .print-bare .table-bordered th{
        border:1px solid #333!important;
    }
    .print-bare table td .lane {
        -webkit-print-color-adjust: exact;
        color-adjust: exact;
        background:#000!important;
    }
    .print-bare table .event.multiple {
        border: 1px solid #000;
        border-radius: 5px;
    }
    /*borders*/
    /*print-dark-border*/
    /* .print-border-dark .table-bordered td,.table-bordered th {
        border:1px solid #333!important;
    } */
    /* .print-border-light .table-bordered td,.table-bordered th {
        border:1px solid red;
    } */

    #verticalDisplay table,
    #abdVerticalDisplay table,
    #horizontalDisplay table,
    #abdHorizontalDisplay table,
    #monthlyDisplay table{
        width:auto!important;
        height:auto!important;
        overflow-x: visible!important;
        overflow-y: visible!important;
    }

    /* #planner-title {
        text-align:center;
    } */
    .fa.fa-pencil,
    .form-group,
    .modal-dialog{
        display:none;
    }
    #year-planner-title {
        font-size: var(--header-size);
    }
    #page-footer div {
        float:none;
        clear:both;
        display:block;
        text-align:center;
        margin:5px auto;
        width:100%;
        font-size: var(--footer-size);
    }
    .col-lg-10 {
        width:100%;
    }

    .choose-years{
        display:none!important;
    }
    .content-calendar {
        margin-top: -100px;
    }
    #planner-title {
        text-align: center !important;
    }
    .year-planner-content-title{
        width:100% !important;
    }
    #warning-calendar-connect{
        display:none !important;
    }
    a[href]:after {
        content: none !important;
    }
}