table.table-bordered{
    overflow-y: auto;
    display: block; 
}

table.table-bordered thead{
    background: #444;
    color: white;
    text-align: center;
    padding: 2px !important;
}
/* table.table-bordered thead th{
    font-weight: 900;
} */
table.table-bordered thead tr th:first-child{
    background: #444;
    /* border: none; */
    position: sticky;
    left: 0;
    z-index:15;
}

td.space{
    background-color: white !important;
    width: 0.1% !important;
    border-top: 0px !important;
    border-bottom: 0px !important;
    border: none !important;
}

td.week_num{
    width: 1% !important;
    white-space: nowrap;
    /* border: 1px solid #ddd !important; */
    text-align: center;
    vertical-align: middle !important;
}

td.week_num_header{
    width: 1% !important;
    white-space: nowrap;
    /* border: 1px solid #ddd !important; */
    text-align: center;
    vertical-align: middle !important;
}

table.table-bordered:not(.align) td{
    width: 3%;
}

table.table-bordered td{
    height: 100%;
    font-size: 11px;      
    padding: 5px !important;
    background-color:#91c2d4;
    font-weight: normal;
    color:#FFF;
}

table.table-bordered td.blankday,
table.table-bordered td.wkend.blankday{
    opacity:0.0;
}

table.table>thead>tr>th{
    padding: 7px;
    text-align: center;
    font-size: 14px;
    text-transform: none;
    font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    position: sticky;
    top: 0;
    background: #444;
	z-index:10;
}
.table > tbody > tr > td:first-child {
    position: sticky;
    left: 0;
    border-left:0px solid;
}
.table-bordered > thead > tr > th, .table-bordered > tbody > tr > th, 
.table-bordered > tfoot > tr > th, .table-bordered > thead > tr > td, 
.table-bordered > tbody > tr > td, .table-bordered > tfoot > tr > td {
    border-right: solid 0px;
	height: 100%;
	position:relative;
}  

/* .table-bordered#table-monthly > tfoot, .table-bordered#table-monthly > tbody, */
.table-bordered#table-monthly > thead > tr > th, .table-bordered#table-monthly > tbody > tr > th, 
.table-bordered#table-monthly > tfoot > tr > th, .table-bordered#table-monthly > thead > tr > td, 
.table-bordered#table-monthly > tbody > tr > td, .table-bordered#table-monthly > tfoot > tr > td {
    border-width: 1px;
    border-color: #FFF;
    text-align: center;
    background-clip: padding-box !important;
}

.table-bordered#table-horizontal > thead > tr > th, .table-bordered#table-horizontal > tbody > tr > th, 
.table-bordered#table-horizontal > tfoot > tr > th, .table-bordered#table-horizontal > thead > tr > td, 
.table-bordered#table-horizontal > tbody > tr > td, .table-bordered#table-horizontal > tfoot > tr > td {
    border-width: 1px;
    background-clip: padding-box !important;
    border-color: #FFF;
    /* max-width: 90px !important; */
    /* border-style: solid;  */
}

.yg-horizontal-public table tbody td.day {
    border-width: 1px;
    background-clip: padding-box !important;
    border-color: #FFF;
    max-width: 40px !important;
}
/* .yg-horizontal-public table tbody tr td a span {
    width: 40px !important;
} */
/* .yg-horizontal-public table thead tr th.day-left {
    min-width : 60px !important;
} */

.yg-horizontal-public table tbody tr td a span {
    display: initial;
}

#year-and-spinner {
    text-align: right;
    margin-top: -80px
}

.table-bordered#table-vertical > thead > tr > th, .table-bordered#table-vertical > tbody > tr > th, 
.table-bordered#table-vertical > tfoot > tr > th, .table-bordered#table-vertical > thead > tr > td, 
.table-bordered#table-vertical > tbody > tr > td, .table-bordered#table-vertical > tfoot > tr > td {
    border-width: 1px;
    border-color: #FFF;
    background-clip: padding-box !important;
}

table.table-bordered td.wkend {
    background-color: #18a7f5;
}
.day br {
    display: none;
}
a.event {
    display: block;
    margin-bottom: 5px;
	z-index: 5; 
    position: relative;
}
a.event span {
    padding: 1px 3px;
    border-radius: 5px;
    display: block;
}
a.event.multiple span {
    margin-left: -2px;
    overflow: hidden;
}
#main-container.all-day a.event.notAllDay{
    display:none;
}
span.dateNo {
    text-align:right;
	display:block;
}

#verticalDisplay td.day .lane, #abdVerticalDisplay td.day .lane {
	width:3px;
	height:100%;
	position:absolute;
	top:0;
}

#verticalDisplay td.day .lane.firstDayInLane, #abdVerticalDisplay td.day .lane.firstDayInLane {
	height: 67%;
    top: 33%;
}
#verticalDisplay td.day .lane.lastDayInLane, #abdVerticalDisplay td.day .lane.lastDayInLane {
	height:15px;
}

#verticalDisplay td.day .lane.lastDayInLane::before, #abdVerticalDisplay td.day .lane.lastDayInLane::before{
   content: '';
    display: inline-block;
    width: 10px;
    height: 10px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    border-radius: 5px;
    background-color: inherit;
    top: 7px;
    position: relative;
    left: -3px;
}

#verticalDisplay td.day .lane.l0, #abdVerticalDisplay td.day .lane.l0 {
	left:3px;
}

#verticalDisplay td.day .lane.l1, #abdVerticalDisplay td.day .lane.l1 {
	left:10px;
}

#verticalDisplay td.day .lane.l2, #abdVerticalDisplay td.day .lane.l2 {
	left:17px;
}

#horizontalDisplay td.day .lane , #abdHorizontalDisplay td.day .lane{
	height:3px;
	width:100%;
	position:absolute;
	right:0;
}
#horizontalDisplay td.day .lane.firstDayInLane , #abdHorizontalDisplay td.day .lane.firstDayInLane {
	width: 67%;
    right: 0;
}
#horizontalDisplay td.day .lane.lastDayInLane , #abdHorizontalDisplay td.day .lane.lastDayInLane {
	width:15px;
	left:0;
}

#horizontalDisplay td.day .lane.lastDayInLane::before , #abdHorizontalDisplay td.day .lane.lastDayInLane::before {
   content: '';
    display: inline-block;
    width: 10px;
    height: 10px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    border-radius: 5px;
    background-color: inherit;
    left: 7px;
    position: relative;
    top: -5px;
}

#horizontalDisplay .day.l0 a.event.lane0, #abdHorizontalDisplay .day.l0 a.event.lane0,
#horizontalDisplay .day.l1 a.event.lane1, #abdHorizontalDisplay .day.l1 a.event.lane1,
#horizontalDisplay .day.l2 a.event.lane2 , #abdHorizontalDisplay .day.l2 a.event.lane2{
    margin-top: 0px;
}

#horizontalDisplay .day.l0 a.event, #abdhorizontalDisplay .day.l0 a.event,
#horizontalDisplay .day.l1 a.event.lane1, #abdHorizontalDisplay .day.l1 a.event.lane1,
#horizontalDisplay .day.l1 .lane.l1, #abdHorizontalDisplay .day.l1 .lane.l1 {
    margin-top: 10px;
}
#horizontalDisplay .day.l1 a.event, #abdHorizontalDisplay .day.l1 a.event,
#horizontalDisplay .day.l2 a.event.lane2, #abdHorizontalDisplay .day.l2 a.event.lane2,
#horizontalDisplay .day.l2 .lane.l2, #abdHorizontalDisplay .day.l2 .lane.l2 {
    margin-top: 20px;
}
#horizontalDisplay .day.l2 a.event, #abdHorizontalDisplay .day.l2 a.event {
    margin-top: 30px;
}

/* CHANGE HORIZONTAL LANE TOP POSITION WITH JQUERY */
/*
#horizontalDisplay td.day .lane.l0 {
	top:8px;
}
#horizontalDisplay td.day .lane.l1 {
	top:15px;
}
#horizontalDisplay td.day .lane.l2 {
	top:22px;
}
*/

/* #verticalDisplay table {
    height: 500px; // height responsive with js
} */
/* HORIZONTAL DISPLAY */
#horizontalDisplay td:first-child, #abdHorizontalDisplay td:first-child{
    background-color: #444;
    color: white;
    font-weight: 600;
    font-size:14px;
    text-align: center;
    vertical-align: middle;
    width: 20px !important;
    border-top: 0;
}

#horizontalDisplay th.day, #abdHorizontalDisplay th.day {
    min-width: 40px;
}
#horizontalDisplay td.mo, #abdHorizontalDisplay td.mo {
    min-width: 60px;
	z-index: 10;
}
/* VERTICAL DISPLAY */
/* #verticalDisplay table {
    overflow-x: hidden;
    height: 500px; // height responsive with js
} */
#verticalDisplay thead th, #abdVerticalDisplay thead th{
    padding: 7px;
    font-size: 14px;
    border-top: 0px solid #FFF;
}
#verticalDisplay tbody tr td:first-child, #abdVerticalDisplay tbody tr td:first-child{
    width: 1% !important;
    background-color: #444;
    color: white;
    text-align: center;
    padding: 2px !important;
    vertical-align: middle;
    font-size:14px;
    font-weight: 600;
    min-width: 50px;
}

#verticalDisplay td.day-left, #abdVerticalDisplay td.day-left {
	z-index: 10;
}


#monthlyDisplay .blankDaySpace{
    height: 15px !important;
    background-color:white;
    /* border-right: 0px; */
    border: none;
}

#monthlyDisplay td.day .lane {
    height: 3px;
    width: 100%;
    position: absolute;
    right: 0;
}

#monthlyDisplay td.day .lane.firstDayInLane {
    width: 67%;
    right: 0;
}

#monthlyDisplay td.day .lane.lastDayInLane {
    width: 15px;
    left: 0;
}

#monthlyDisplay td.day .lane.lastDayInLane:before {
  content: '';
  display: inline-block;
  width: 10px;
  height: 10px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  background-color: inherit;
  left: 7px;
  position: relative;
  top: -5px;
}

#monthlyDisplay .day.l0 a.event.lane0,
#monthlyDisplay .day.l1 a.event.lane1,
#monthlyDisplay .day.l2 a.event.lane2{
    margin-top: 0px;
}

#monthlyDisplay .day.l0 a.event,
#monthlyDisplay .day.l1 a.event.lane1,
#monthlyDisplay .day.l1 .lane.l1 {
    margin-top: 10px;
}

#monthlyDisplay .day.l1 a.event,
#monthlyDisplay .day.l2 a.event.lane2,
#monthlyDisplay .day.l2 .lane.l2 {
    margin-top: 20px;
}

#monthlyDisplay .day.l2 a.event {
    margin-top: 30px;
}

#monthlyDisplay .first_child{
    border-left: 1px solid #ddd;
}

.header-navbar-fixed #main-container.hideHeader,
.fullScreen .header-navbar-fixed #main-container{
    padding-top: 0px;
}
.fullScreen #page-container.sidebar-l.sidebar-o {
    padding-left: 0px;
}

.nav-main.calendars li,
.nav-main.plutio-filters li {
    padding: 0px 20px;
}
.nav-main.calendars li.nav-main-heading,
.nav-main.plutio-filters li.nav-main-heading {
    padding: 22px 20px 6px 20px;
}


/*@page { size: landscape; }*/

#sidebar-scroll
{
  touch-action: manipulation;
}

table i.fa.fa-calendar,
table span.fa.fa-cloud {
    display: none;
}
button.btn.btn-default.btn-closefullscreen {
    position: absolute;
}											
 .options i {
    margin-right:10px;
}
.list.options a {
    color:#000;
}
.options label {
    font-size: 14px;
    font-weight: normal;
	margin-right: 3px;
}

.options input[type="number"],
.options input[type="text"]{
    color:#000;
    margin-left:10px;
    padding:0 0px 0 3px;
    background:#FFF;
}

#numMonthInput {
    margin-left: 0px;
}

.options input[type="checkbox"]{
    margin-left: 10px;
    width: 14px;
    height: 14px;
    top: 2px;
    position: relative;
}

@media screen and (min-width: 768px) {
    .content p, .content .push, .content .block, .content .items-push > div {
        margin-bottom: 0px;
    }
    .content {
        padding: 10px 30px 1px;
    }
}

.tooltip-inner {
    margin-left: 0%;
    max-width: 50%;
    width: 50%;
    min-width: 200px;
    z-index:9999 !important;
}
.tooltip{
    position: fixed !important;
    z-index:9999 !important;
}

.single-line td.day {
    max-width: 0;
}

.single-line a.event {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.cal-event td.date-month {
    max-width: 0;
}

.cal-event a.event {
    max-width: 77px;
    overflow: hidden;
    text-overflow: ellipsis;
}

#table-vertical tbody tr td a.event {
    max-width : none;
}

#table-monthly tbody tr td a.event {
    max-width : none;
    /* white-space: normal; */
}

.MuiTableCell-root,
.MuiTypography-caption,
.MuiInputBase-input,
.MuiTypography-root,
.MuiChip-label,
.MuiMenuItem-root,
.MuiDialogActions-spacing > :not(:first-child),
.MuiButton-label,
.MuiFormHelperText-root,
.MuiPickersDay-dayLabel {
    font-size: 14px !important;
}

table.table#table-horizontal > thead > tr > th.date-month {
    width: calc(100% / 31);
    min-width: calc(100% / 31);
  }
table.table#table-vertical > thead > tr > th.mo { 
    --width: calc(100% / 12);
    width: var(--width);
  }
#public-number.random {
    font-weight: 900;
    font-size: 12px;
}

#verticalDisplay tbody tr td a.samd span, #abdVerticalDisplay tbody tr td a.samd span {
    display: none;
}

#monthlyDisplay tbody tr td a.samd span {
    display: none;
}

/* #buttonForPublic {
    display: flex;
    justify-content: right;
    align-items: right;
    height: 50px;
} */

